/* Not everyone likes this so I added it as mixin to easy remove */
/* Not everyone likes this so I added it as mixin to easy remove */
/** Ionic CSS Variables **/
:root {
  --ion-grid-column-padding: 0 ;
}

@media screen and (max-width: 320px) {
  .hide-xs {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (max-width: 640px) {
  .hide-sm,
  .hide-mobile,
  .show-desktop {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (min-width: 641px) {
  .hide-desktop,
  .show-mobile {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (min-width: 961px) {
  .hide-md {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (min-width: 1025px) {
  .hide-lg {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (min-width: 1441px) {
  .hide-xl {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
}

.data-group {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 1px;
}
.data-group_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  height: 36px;
  width: 36px;
}
.data-group_data {
  width: 100%;
  text-align: left;
}
.data-group_data > label {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.58px;
}
.data-group_data > span,
.data-group_data .large {
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
}
.data-group_data-center {
  text-align: center;
}
.data-group_icon-end {
  padding-left: 12px;
}
.data-group_icon-end .data-group_icon {
  order: 1;
  margin-left: 11px;
  margin-right: 0;
}
.data-group.no-padding.data-group_icon-end {
  padding-left: 12px !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.tiny-text {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 10px;
}

.small-text {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 12px;
}

.text-error {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
}

.mobile-small {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 14px;
}
@media screen and (max-width: 640px) {
  .mobile-small {
    font-size: 12px;
  }
}

.normal-text {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 14px;
}

.large-text {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 16px;
}

.huge-text {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 30px;
}

.small-title {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 18px;
  margin-bottom: 8px;
}

.medium-title {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 22px;
}

.large-title {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: 0;
  color: inherit;
  line-height: inherit;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .large-title {
    font-size: 24px;
    margin-bottom: 14px;
  }
}

.huge-title {
  font-family: Lato, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-display: swap;
  font-size: 36px;
  margin-bottom: 32px;
}
@media screen and (max-width: 640px) {
  .huge-title {
    font-size: 30px;
    margin-bottom: 14px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #3a80c5;
  margin-bottom: 29px;
}

.light-divider {
  width: 100%;
  height: 1px;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.5019607843);
  box-sizing: border-box;
}

.vertical-divider {
  height: 30px;
  width: 1px;
  background-color: #3a80c5;
  margin: 0px 9px;
}

.small-pad {
  padding: 3px 9px !important;
}

.short-button {
  min-height: 30px !important;
  padding: 6px 12px !important;
  font-size: 12px;
}

.app-square-textbox {
  max-height: 50px;
  min-height: 50px;
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.15);
}

.app-checkbox {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  margin: 15px 7px 15px 0px;
}

.large-button {
  min-height: 54px;
  margin-bottom: 15px;
  padding: 5px 12px;
  letter-spacing: 0.44px;
}

button::-moz-focus-inner {
  border: 0;
}

.generic-dialog-link-button {
  display: block;
  margin: 15px -8px !important;
  color: #ffffff !important;
  background-color: #003893 !important;
  text-transform: uppercase !important;
}
.generic-dialog-link-button.inverted {
  color: #003893 !important;
  background-color: #ffffff !important;
  border: 1px solid #003893;
}

.base-btn-size {
  min-width: 220px;
  height: 50px;
}

input:focus {
  outline: black 2px solid;
}

.pointer {
  cursor: pointer;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}