﻿// colors
$primaryColor: #003893;
$subTitleColor: #62a5f8;
$primarySemiTransparent: rgba(0, 56, 147, 0.6);
$primaryColorOpaque: rgba(0, 56, 147, 0.8);
$primaryColorMed: rgba(58, 128, 197, 0.3);
$primaryColorLight: rgba(58, 128, 197, 0.5);
$primaryColorLighter: #3a80c5;
$primaryColorLightAccessible: #306ba6;
$primaryContrastColor: #d81e05;
$successColor: #009966;
$gray: #cccccc;
$lightGray: #f8f8f8;
$darkGray: #525252;
$white: #fff;
$whiteSemiTransparent: rgba(255, 255, 255, 0.7);
$white10Transparent: rgba(255, 255, 255, 0.9);
$black: #000;
$darkPrimary: #15284C;
$secondaryColor: #15284C;
$primaryColorYellow: #FED80E;
$iconLightColor: #ADADAD;

$successTextColor: #009966;
$errorColor: #d81e05;
$brightErrorColor: #ef1e05;
$brightCancelColor: rgba(239, 28, 5, 0.50);
$cancelColor: #d81e05;
$tabColor: #009966;
$textColor: #003893;
$textColorInverted: #ffffff;
$tabTextColor: #808080;
$bgContainer: #ffffff;
$borderLight: #cccccc;
$borderError: #FA1223;
$bgGray: #f7f7f7;
$iconColor: #d81e05;
$bgOverlay: rgba(0, 0, 0, 0.4);
$tipOverlay: rgba(0, 0, 0, 0.7);
$facilityClosedText: #FA1223;

$accentColor: #ffffff;
$bgActiveColor: #f9f9f9;
$divisorColor: #d9d9d9;
$clearButtonColor: #c0c0c0;
$borderColor: #eee;
$tabBackground: #E2E2E2;

$darkBlue: #15284C;
$brightRed: #b61600;
$darkRed: #700800;
$medGray: #7f7d7d;
$darkBrown: #4d2e2e;
$purple: rgb(117, 0, 102);
$medBrown: #7d5518;
$darkGold: #4b2b00;
$medGold: #d4bf95;
$darkGreen: #215143;
$medGreen: #419553;
$lightGreen: #60CE6E;
$yellow: #ddbe2a;
$orange: #e06625;


$lightGrayOverlay: rgba(0, 0, 0, 0.15);
$lightestWhiteOverlay: rgba(255, 255, 255, 0.1);
$lighterWhiteOverlay: rgba(255, 255, 255, 0.15);
$lightWhiteOverlay: rgba(255, 255, 255, 0.25);
$medWhiteOverlay: rgba(255, 255, 255, 0.35);
$medBlueOverlay: rgba(0, 0, 255, 0.25);
$lightRedOverlay: rgba(255, 0, 0, 0.15);
$medRedOverlay: rgba(255, 0, 0, 0.45);
$medGreenOverlay: rgba(0, 255, 0, 0.25);
$medYellowOverlay: rgba(255, 212, 64, 0.25);

// sizes and radius

$defaultPadding: 25px;

$defaultRadius: 4px;

$hugeFontSize: 30px;
$bigFontSize: 24px;
$mediumBigFontSize: 22px;
$mediumFontSize: 16px;
$defaultFontSize: 14px;
$smallFontSize: 12px;
$extraSmallFontSize: 11px;

// Responsive

$break-xsmall: 320px;
$shrink-xsmall-breakpoint: 400px;
$break-small: 640px;
$shrink-medium-breakpoint: 800px;
$break-medium: 960px;
$break-large: 1024px;
$break-xlarge: 1440px;

$modal-breakpoint: 415px;

$accessibiltyPrimaryColorLighter: #3270AE;
$accessibiltyBorderColor: #63B2DD;
$accessibiltyFocusColor: #A3AAB1;
$accessibiltySelectedColor: #54C96F;

// named after namethatcolor app (https://chir.ag/projects/name-that-color)
$emperor: #505050;

// AB Test colors
$abYellow: #f3d00b;
$abYellowBorder: #fAbb2d;
$abGray: #f5f5f5;
$abSoftGray: #ebecee;

// Redesign
$signInBackground: #F8FAFC;
